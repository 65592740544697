/**
 * @flow
 */

import * as React from 'react';
import Page from 'src/components/Page';
import {MONT_DEMOS_FFM_URL, CONTACT_OG_IMAGE} from 'src/constants';
import IconLink, {
  // twitterIconLinkInfo,
  instagramIconLinkInfo,
  facebookIconLinkInfo,
  tiktokIconLinkInfo,
  spotifyIconLinkInfo,
  appleMusicIconLinkInfo,
  youtubeIconLinkInfo,
  // etsyIconLinkInfo,
  // emailIconLinkInfo,
} from 'src/components/icons/IconLink';
import {useTheme} from '@emotion/react';

import type {IconLinkInfo} from 'src/components/icons/IconLink';

const styles = {
  quip: (theme) => ({
    textAlign: 'center',
    margin: `${theme.pagePadding}rem 0`,
  }),
  iconLinkList: {
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'wrap',
  },
  iconLinkGroups: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  iconLinkGroup: (theme) => ({
    padding: `${theme.pagePadding}rem 1.2rem`,
  }),
};

type IconLinkGroupProps = $ReadOnly<{
  title: string,
  children: React.Node,
}>;

function IconLinkGroup(props: IconLinkGroupProps): React.Node {
  const theme = useTheme();
  const {title, children} = props;
  const additionalIconLinkGroupStyle = {
    // intentionally just less than enough to fit 3 groups on a line
    width: theme.maxMainContentWidth / 2.8,
  };
  return (
    <section css={[styles.iconLinkGroup, additionalIconLinkGroupStyle]}>
      <h2>{title}</h2>
      <ul css={styles.iconLinkList}>
        {React.Children.map(children, (child, idx) => (
          <li key={idx}>{child}</li>
        ))}
      </ul>
    </section>
  );
}

type ContactIconLinkProps = $ReadOnly<{
  showLabel?: ?boolean,
  showIcon?: ?boolean,
  iconLinkInfo: IconLinkInfo,
}>;
function ContactIconLink(props: ContactIconLinkProps): React.Node {
  const {iconLinkInfo, showLabel, showIcon} = props;
  const theme = useTheme();
  return (
    <IconLink
      showIcon={showIcon}
      showLabel={showLabel}
      iconLinkInfo={iconLinkInfo}
      color={theme.linkColor}
      hoverColor={theme.linkHoverColor}
    />
  );
}

export default function ContactPage(): React.Node {
  return (
    <Page
      title='Contact'
      canonicalPath='/contact/'
      ogImage={CONTACT_OG_IMAGE}
      description='Shop links, social links, email, and other contact information for artist and musician Southerfields'
      verticallyCenterContent={true}
    >
      <article>
        <div css={styles.quip}>you can find me @southerfields most places</div>
        <div css={styles.iconLinkGroups}>
          <IconLinkGroup title='Social'>
            <ContactIconLink iconLinkInfo={instagramIconLinkInfo} />
            <ContactIconLink iconLinkInfo={facebookIconLinkInfo} />
            <ContactIconLink iconLinkInfo={tiktokIconLinkInfo} />
            {/* <ContactIconLink iconLinkInfo={twitterIconLinkInfo} /> */}
          </IconLinkGroup>
          <IconLinkGroup title='Stream'>
            <ContactIconLink iconLinkInfo={spotifyIconLinkInfo} />
            <ContactIconLink iconLinkInfo={appleMusicIconLinkInfo} />
            <ContactIconLink iconLinkInfo={youtubeIconLinkInfo} />
            <ContactIconLink
              iconLinkInfo={{
                label: 'More...',
                href: MONT_DEMOS_FFM_URL,
                Icon: () => null,
              }}
              showIcon={false}
              showLabel={true}
            />
          </IconLinkGroup>
          {/* <IconLinkGroup title='Shop'>
            <ContactIconLink iconLinkInfo={etsyIconLinkInfo} showLabel={true} />
          </IconLinkGroup> */}
          {/* <IconLinkGroup title='Contact'>
            <ContactIconLink
              iconLinkInfo={emailIconLinkInfo}
              showLabel={true}
            />
          </IconLinkGroup> */}
        </div>
      </article>
    </Page>
  );
}
